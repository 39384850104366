import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import EllisHelmet from '../components/EllisHelmet'
import { formatDatoCMSData } from '../services/datoCMS.service'

export default function Employment() {
  const currentPage = "employment";
  const title = 'Employment Opportunities';


  const query = useStaticQuery(graphql`
  query employmentQuery {
    datoCmsEmploymentOpportunity {
      hiringStatus
      requisites {
        requisite
      }
      missionStatement
      locationDowntownEmail
      locationBryantEmail
    }
  }`);

  const employmentData = formatDatoCMSData(query);
  const hiringStatus = employmentData.hiringStatus ? 'Currently Seeking Applicants' : 'Not Taking Applications at This Time';
  const missionStatement = employmentData.missionStatement;

  // Our events are built from our regular menu items
  const requisites = employmentData.requisites.map((requisite) => {
    return (<li key={requisite.name}>{requisite.requisite}</li>);
  });

  const locationDowntownEmail = (employmentData.locationDowntownEmail ?
    <React.Fragment>
      <h3 style={{ marginTop: '1em', marginBottom: 0 }}>Downtown Edmond</h3>
      <a href={"mailto:" + employmentData.locationDowntownEmail}>{employmentData.locationDowntownEmail}</a>
    </React.Fragment>
    : null);

  const locationBryantEmail = (employmentData.locationBryantEmail ?
    <React.Fragment>
      <h3 style={{ marginTop: '1em', marginBottom: 0 }}>33rd & Bryant</h3>
      <a href={"mailto:" + employmentData.locationBryantEmail}>{employmentData.locationBryantEmail}</a>
    </React.Fragment>
    : null);

  return (
    <Layout className="menuPage" location="employment">
      <EllisHelmet page={currentPage} title={title} location={null} />
      <div id="employmentpage" className="wrapper style1">
        <div className="container">
          <header className="major">
            <h2>Employment Opportunities</h2>
          </header>
          <div className="box alt">
            <div className="row">
              <div className="col-8 col-12-xsmall">
                <h3>Hiring Status: {hiringStatus}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-8 col-12-xsmall">
                <ul>
                  {requisites}
                </ul>
                <p>{missionStatement}</p>
                <p>If what we offer appeals to you and you feel that you may be a valuable addition to our team, please submit your resume, weekly availability, and desired number of hours each week here:</p>
                {locationDowntownEmail}
                {locationBryantEmail}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
